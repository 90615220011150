import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { Consumer } from 'store/createContext';
import Layout from 'components/layout';
import LoginForm from 'components/login';

class Login extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let state = localStorage['appState'];
    if (state) {
      navigate('/account');
    }
  }
  render() {
    return (
      <Consumer>
        {({ toggleLoginStatus }) => (
          <Layout>
            <div className="mt-5">
              <LoginForm
                toggleLoginStatus={toggleLoginStatus}
                loginRedirect="account"
              />
            </div>
          </Layout>
        )}
      </Consumer>
    );
  }
}

export default Login;
